<template>
  <div>
    <div class="">
      <div class="we-flex-col we-justify-content-center head">
        <div class="text-align-c">
          <van-image width="200" :src="require('@assets/images/logo.png')" />
        </div>
        <!-- <p class="we-font-size-xxlo text-align-c">中科芯创</p> -->
      </div>
      <div class="fontcolor we-text-p15 lineheight ">

        中科芯创集团成立于2021年，是中国科学院微电子研究所健康电子研发中心的成果转化平台，集研发、生产、销售、服务为一体的高科技企业。中科芯创专注于多维度智能化健康监测设备与系统的创新，在国家政策的规划指导下，在对行业现状与市场需求的充分调研分析后，集团领导结合公司战略，将“智慧中医”作为集团要打造的首张“名片”，中科芯创的首个五年规划将聚焦“智慧中医”在专业医疗、教育传承、大健康三大领域的应用与赋能。<br>

        中科芯创依托中国科学院微电子研究所，在集团战略目标指导下，开发了一系列软硬件产品并整合行业解决方案，从芯片、传感器、软件的设计研发到整机制造，全部自给自足，拥有完全自主的知识产权。其中多通道中医脉诊仪、舌诊仪获得北京市创新医疗器械认证，并拥有多项国家专利，软件著作权等知识产权。<br>

        中科芯创与各级政府机关、医疗机构、国内外高校和应用体系深入合作，曾承担中国科学院重点部署项目“三部九候脉诊仪关键技术研究”；国家科技重大专项“基于国产化MEMS和CMOS工艺的智能中医脉诊核心芯片与设备研发”；牵头主导“一带一路中医国际远程诊疗服务平台”；承接四川省2022年生命健康重大科技专项；并与国内多所知名研究机构合作共同承担了国家01专项，参与了中医诊断仪器国际、国家行业标准的编制及修订。<br>

        中科芯创坚持自主创新，将打造“AI+芯片+互联网+中医”的“芯”模式，让“中华文明的瑰宝”造福中华人民的同时能够“走出去”，让全世界的人民享受到现代化中医对生命健康的关爱！

      </div>
      
    </div>
  </div>
</template>

<script>
import {  Image as VanImage } from "vant";
// import { login, authoritys } from "@api/auth";
// import { systemInfo } from "@api/system";
// import storage from "../util/storage";
export default {
  name: "about",
  data() {
    return {};
  },

  components: {
    
    [VanImage.name]: VanImage,
  },

  methods: {},
  mounted() {},
};
</script>
<style lang="less" scope>

.head {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>